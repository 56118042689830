export const pacingColor = (pacing) => {
  if (pacing > 0.95 && pacing < 1.05) {
    return '#99B898' // green
  } else if (pacing > 1.05 && pacing < 1.15) {
    return '#ff847c'
  } else if (pacing > 1.15) {
    return '#e84a5f'
  } else if (pacing < 0.95 && pacing > 0.85) {
    return '#abd9e9'
  } else if (pacing < 0.85) {
    return '#74add2'
  }
  return ''
}

export const renderSparkLine = (spend, pacing = 0) => {
  // return `https://api.fluencyinc.co/sparkLine?d=1,10.2,15.0,123,455,764,15.3&clr=2A363B&bg=ffffff&fill=ffffff&w=250&h=55`
  // const rowColors = ['#ffffff', '#f2f2f2']
  return `https://api.fluencyinc.co/sparkLine?d=${spend.map(x => Math.round(x)).join(',')}&clr=${pacingColor(pacing).replace('#', '')}&bg=ffffff&fill=ff847c&w=140&h=22`
}
